<template>
  <CCard body-wrapper>
    <div class="d-flex">
      <h2 class="flex-grow-1">Склады</h2>
      <CButton
        v-if="!isMainStoreFilter"
        @click="goToStoreCreation"
      >
        Добавить
      </CButton>
    </div>
    <CNav variant="tabs" class="mt-2 no-border">
      <CNavItem @click.native.stop="changeTab('Store::Main')" :active="filters.type == 'Store::Main'">Основные</CNavItem>
      <CNavItem @click.native.stop="changeTab('Store::Retail')" :active="filters.type == 'Store::Retail'">Розничные магазины</CNavItem>
      <CNavItem @click.native.stop="changeTab('Store::Supplier')" :active="filters.type == 'Store::Supplier'">Поставщики</CNavItem>
    </CNav>
    <CDataTable
      :items="list"
      :fields="tableFields"
      hover
      striped
      :loading="loading"
     >
     <template #loading>
       <CElementCover
         :opacity="0.8"
       >
         <CSpinner
           class="custom-spinner"
           color="info"
         />
       </CElementCover>
     </template>
     <template #no-items-view>
       <div v-if="isMainStoreFilter" class="text-center d-flex align-items-center justify-center flex-column">
         <span class="font-xl mb-2">Основные склады создаются настройку 1С</span>
         <CButton
           color="primary"
           :to="{ name: 'admin.warehouses.index'}"
         >
           Перейти
         </CButton>
       </div>
       <div v-else class="text-center d-flex align-items-center justify-center flex-column">
         <span class="font-xl mb-2">Ничего не найдено</span>
         <CButton
           color="primary"
           @click="goToStoreCreation"
         >
           Создать
         </CButton>
       </div>
     </template>
     <template #address="{ item }">
       <td class="align-middle">
         <div v-if="!item.address">
           Необходимо заполнить адрес
           <CIcon class="icon-danger ml-2" name="cilWarning" />
         </div>
         <div v-else-if="item.address.identified">
           {{ item.address.fullCleanAddress }}
         </div>
         <div v-else>
           {{ item.address.fullAddress }}
           <CIcon class="icon-danger ml-2" name="cilWarning" />
         </div>
       </td>
     </template>
     <template #actions="{ item }">
       <td class="align-middle text-right">
         <CButton
           color="danger"
           title="Удалить"
           @click="destroyStore(item)"
         >
           <CIcon name="cilTrash" />
         </CButton>
         <CButton
           color="secondary"
           title="Подробнее"
           :to="{ name: 'admin.stores.show', params: { id: item.id } }"
         >
           <CIcon name="cilPencil" />
         </CButton>
       </td>
     </template>
   </CDataTable>
  </CCard>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'StoresList',
  data() {
    return {
      list: [],
      filters: {
        type: 'Store::Main'
      },
      loading: false,
    }
  },
  computed: {
    tableFields() {
      return [
        {key: 'name', label: 'Название', _classes: ['font-weight-bold', 'align-middle']},
        {key: 'address', label: 'Адрес'},
        {key: 'actions', label: 'Действия', _style: 'text-align: right;'}
      ]
    },
    isMainStoreFilter() {
      return this.filters.type == 'Store::Main';
    }
  },
  methods: {
    ...mapActions(['createDraftStore']),
    async goToStoreCreation() {
      const draftObject = {type: this.filters.type}
      const uuid = await this.createDraftStore(draftObject);
      this.$router.push({name: 'admin.stores.create', query: {uuid}});
    },
    changeTab(type) {
      this.filters.type = type;
      this.list = [];
      this.loadList();
    },
    async destroyStore(store) {
      try {
        await this.axios.delete(`api/stores/${store.id}`);
        const index = this.list.findIndex(el => el.id == store.id);
        this.list.splice(index, 1);
      } catch (e) {
        console.log(e)
      }
    },
    async loadList() {
      if (this.isMainStoreFilter) {
        return;
      }
      try {
        this.loading = true;
        const {data} = await this.$axios.request({url: 'api/stores', params: this.filters})
        this.list = data;
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
